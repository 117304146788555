/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/indigo-pink.css';

html,
body {
  height: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: Montserrat, sans-serif;
}

.container {
  max-width: 1140px;
  margin: auto;
  padding: 32px 8px 32px 8px;
}
