@use '@angular/material' as mat;
@import '@angular/material/theming';

$custom-typography: mat.define-legacy-typography-config(
  $font-family: 'Montserrat',
);
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($custom-typography);`
@include mat.all-legacy-component-typographies($custom-typography);
@include mat.legacy-core();

$creasources-blue: (
  50: #ffffff,
  100: #c3d0fa,
  200: #8fa8f6,
  300: #4d74f1,
  400: #305eef,
  500: #1448ed,
  600: #103fd2,
  700: #0e36b6,
  800: #0c2e99,
  900: #0a257d,
  A100: #ffffff,
  A200: #9bb3ff,
  A400: #3565ff,
  A700: #2657f5,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

$creasources-orange: (
  50: #ffffff,
  100: #ffffff,
  200: #fff1e4,
  300: #ffcb9c,
  400: #ffbb7e,
  500: #ffab5f,
  600: #ff9b40,
  700: #ff8b22,
  800: #ff7b03,
  900: #e46c00,
  A100: #ffffff,
  A200: #fffbf8,
  A400: #ffc692,
  A700: #ffb878,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $dark-primary-text,
    800: $dark-primary-text,
    900: $dark-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  ),
);

$creasources-app-primary: mat.define-palette($creasources-blue);
$creasources-app-accent: mat.define-palette($creasources-orange);
$creasources-app-theme: mat.define-light-theme(
    (
      color: (
        primary: $creasources-app-primary,
        accent: $creasources-app-accent,
      ),
    )
);

@include mat.all-legacy-component-themes($creasources-app-theme);


:root {
  --color-primary: #{mat.get-color-from-palette($creasources-app-primary)};
  --color-accent: #{mat.get-color-from-palette($creasources-app-accent)};
  --color-light-primary-text: #{$light-primary-text};
}
